import { QueryClientProvider } from '@tanstack/react-query';
import Header from '../../components/Layout/Home/components/header';
import { queryClient } from '../../lib/react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from '../../components/Layout/Home/components/footer';

const NotFound = ({ withHeader }: { withHeader: boolean }) => {
  const renderNotFound = () => {
    return (
      <div className="row no-gutter">
        <div className="col-md-12 col-lg-12">
          <div className="d-flex align-items-center py-5" style={{ minHeight: '60vh' }}>
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-lg-8" style={{ margin: 'auto', textAlign: 'center' }}>
                  <h2 className="login-heading mt-5 mb-4">
                    <span className="text-danger">Opps!</span> Page not found.
                  </h2>

                  <p className="registerfont">The page you’re looking for doesn’t exist.</p>

                  <a href="/"> Go Home</a>

                  <div className="text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  // added query client and router to render header
  return withHeader ? (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="wrapper home-layout-container">
          <Header hidelinks={true} />
          <article>{renderNotFound()}</article>
          <Footer hidelinks={true} />
        </div>
      </Router>
    </QueryClientProvider>
  ) : (
    renderNotFound()
  );
};

export default NotFound;
