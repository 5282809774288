import React from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { ErrorMessage } from './ErrorMessage';
interface Option {
  label: string;
  value: string;
}

interface InputProps {
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  options?: Option[];
  defaultvalue?: string;
  menuPortalTarget?: HTMLElement | null | undefined;
  onChange?: any;
  showErrorLabel?: boolean;
}

const customStyles = {
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0px 20px',
    margin: '0px',
    textAlign: 'left',
  }),
  input: (provided: any) => ({
    ...provided,
    padding: '0px 2px',
    margin: '0px',
  }),

  control: (provided: any) => ({
    ...provided,
    borderWidth: '0px',
    boxShadow: 'none',
    minHeight: '48px',
    borderRadius: '0px',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    padding: '0px',
    textAlign: 'left',
  }),
};

const StaticDataSelectField: React.FC<InputProps> = (args) => {
  const {
    placeholder,
    label,
    name,
    options,
    defaultvalue,
    className = '',
    showErrorLabel = true,
    onChange,
    menuPortalTarget = document.querySelector('body'),
  } = args;

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);
  const handleFieldChange = (value: any) => {
    if (onChange) {
      onChange(value);
    }
  };  

  return (
    <div>
      {label && <label className="form-label">{label}</label>}

      <div className={`${className}`}>
        {options && (
          <Controller
            render={({ field: { onChange, value } }) => (
              <Select
                styles={customStyles}
                name={name}
                placeholder={placeholder}
                options={options}
                value={options.find((c) => c.value === (value ? value.toString() : value))}
                onChange={(val: any) => {
                  onChange(val.value);
                  handleFieldChange(val);
                }}
                defaultValue={options.find((c) => c.value === defaultvalue)}
                menuPortalTarget={menuPortalTarget}
              />
            )}
            name={name}
            control={control}
            rules={{ required: true }}
          />
        )}
      </div>
      {showErrorLabel && (
        <ErrorMessage error={error} name={name} fieldLabel={label || placeholder} />
      )}
    </div>
  );
};

export default StaticDataSelectField;
