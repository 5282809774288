import i18next from 'i18next';
import * as _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AsyncSelect,
  CheckBoxField,
  EmailField,
  FileSelectionField,
  PhoneField,
  TextAreaField,
  TextField,
} from '../../../../components/Form';
import { Option } from '../../../../components/Form/props';
import { genericApi } from '../../../../services/genericApi';

const ListingProfile = (args: any) => {
  const { watch, setValue } = useFormContext();
  const { t } = useTranslation();
  const { fieldName } = args;
  const country = watch(`${fieldName}.country`);
  const businessEmail = watch(`${fieldName}.businessEmail`);
  const businessPhone = watch(`${fieldName}.businessPhone`);

  useEffect(() => {
    if (!businessPhone) {
      const phoneNumber = watch(`guideProfile.phoneNumber`);
      setValue(`${fieldName}.businessPhone`, phoneNumber);
    }

    if (!businessEmail) {
      const email = watch(`guideProfile.email`);
      setValue(`${fieldName}.businessEmail`, email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessEmail, businessPhone]);

  const onCityChange = useCallback(
    (value: string) => {
      const countryCode = country;
      return new Promise<Option[]>((resolve) => {
        const data = genericApi
          .getCityByCountryMapBoxLocation(value, countryCode)
          .then((result) => {
            return _.map(result, (obj: any) => ({
              label: obj[`place_name_` + i18next.language],
              value: obj?.text,
            }));
          });
        resolve(data);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [country, i18next.language]
  );

  const loadCountryOptions = React.useCallback(
    (value: string) => {
      return new Promise<Option[]>((resolve) => {
        const data = genericApi.getCountryMapBoxLocation(value).then((result) => {
          return _.map(result, (obj: any) => ({
            label: obj[`place_name_` + i18next.language],
            value: obj?.properties?.short_code,
            obj: obj,
          }));
        });
        resolve(data);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18next.language]
  );

  const onCountryChange = (option: any) => {
    setValue(`${fieldName}.city`, '', { shouldDirty: true });
  };

  return (
    <>
      <h5>{t('guide.businessProfile.title')}</h5>
      <p className="mt-3">{t('guide.businessProfile.description')}</p>
      <div className="row">
        <div className="form-group col-md-6">
          <TextField
            name={`${fieldName}.businessName`}
            placeholder={t('guide.fields.businessName.placeholder')}
          />
        </div>
        <div className="form-group col-md-6">
          <EmailField
            name={`${fieldName}.businessEmail`}
            placeholder={t('guide.fields.email.placeholder')}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-6">
          <PhoneField
            name={`${fieldName}.businessPhone`}
            placeholder={t('guide.fields.phoneNumber.placeholder')}
          />
        </div>
        <div className="form-group col-md-6">
          <AsyncSelect
            className="product_select"
            name={`${fieldName}.country`}
            placeholder={t('guide.fields.country.placeholder')}
            loadOptions={loadCountryOptions}
            onChange={onCountryChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-6">
          <AsyncSelect
            className="product_select"
            name={`${fieldName}.city`}
            placeholder={t('guide.fields.city.placeholder')}
            loadOptions={onCityChange}
            disabled={!country}
          />
        </div>
        <div className="form-group col-md-6">
          <TextField
            name={`${fieldName}.postalCode`}
            placeholder={t('guide.fields.postalCode.placeholder')}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-6">
          <TextField
            name={`${fieldName}.streetAddress`}
            placeholder={t('guide.fields.streetAddress.placeholder')}
          />
        </div>
        <div className="form-group col-md-6">
          <TextField
            name={`${fieldName}.website`}
            placeholder={t('guide.fields.website.placeholder')+ ` ${t('Website.hintText')}`}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="form-group col-md-12">
          <TextAreaField
            label={t('guide.fields.businessDescription.label')}
            name={`${fieldName}.businessDescription`}
            placeholder={t('guide.fields.businessDescription.placeholder')}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <CheckBoxField
            name={`${fieldName}.certificates`}
            label={t('guide.fields.certificates.label')}
          />
        </div>
      </div>
      <div className='row'>
      <div className="form-group col-md-12">
          <CheckBoxField
            name={`${fieldName}.licensesCertificates`}
            label={t('guide.fields.licensesCertificates.label')}
          />
        </div>
      </div>
      <div className="row">
        <FileSelectionField
          name={`${fieldName}.businessMedia`}
          label={t('guide.fields.businessMedia.label')}
          selectedImagePath={'GenGuideBkgnd'}
          disableUploadOption={true}
        />
      </div>
    </>
  );
};

export default ListingProfile;
