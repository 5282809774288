/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import './style.css';
import NewsLetter from '../../../../NewsLetter';

function Footer(props: any) {
  const { t } = useTranslation();
  return (
    <>
      <footer className="page-footer font-small bg-white pt-4 mt-5" style={{ color: '#212529' }}>
        <div className="container text-center text-md-left">
          <div className="row">
            <div className="col-md-6 mx-auto">
              <h5 className="font-weight-bold  mt-3 mb-4">{process.env.REACT_APP_SITE_NAME}</h5>
              <p>{t('nav.description')}</p>
            </div>
            <div className="col-md-6 mx-auto">
              <NewsLetter />
              <hr className="clearfix w-100"></hr>
              {/* <ul className="list-unstyled list-inline text-center">
                <li className="list-inline-item">
                  <a className="btn-floating btn-fb mx-1">
                    <i className="fa fa-facebook-f"> </i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="btn-floating btn-tw mx-1">
                    <i className="fa fa-twitter"> </i>
                  </a>
                </li>

                <li className="list-inline-item">
                  <a className="btn-floating btn-dribbble mx-1">
                    <i className="fa fa-dribbble"> </i>
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        {!props.hidelinks && (  <div className="row">
            <div className="col-md-3 mx-auto">
              <h5 className="font-weight-bold  mt-3 mb-4">{t('nav.terms')}</h5>
              <ul className="list-unstyled">
                <li>
                  <NavLink to="/terms">{t('nav.terms')}</NavLink>
                </li>
                <li>
                  <NavLink to="/privacy">{t('nav.privacy')}</NavLink>
                </li>
              </ul>
            </div>

            <hr className="clearfix w-100 d-md-none" />

            <div className="col-md-3 mx-auto">
              <h5 className="font-weight-bold  mt-3 mb-4"> {t('nav.company')}</h5>

              <ul className="list-unstyled">
                <li>
                  <NavLink to="/about">{t('nav.about')}</NavLink>
                </li>

                <li>
                  <NavLink to="/news">{t('nav.newsroom')}</NavLink>
                </li>
                <li>
                  <NavLink to="">{t('nav.facebook')}</NavLink>
                </li>
                <li>
                  <NavLink to="">{t('nav.instagram')}</NavLink>
                </li>
              </ul>
            </div>

            <hr className="clearfix w-100 d-md-none" />

            <div className="col-md-3 mx-auto">
              <h5 className="font-weight-bold  mt-3 mb-4">{t('nav.contactSupport')}</h5>

              <ul className="list-unstyled">
                <li>
                  <NavLink to="/contact">{t('nav.contact')}</NavLink>
                </li>
                <li>
                  <NavLink to="/faq/guest">{t('nav.faq_guest')}</NavLink>
                </li>
                <li>
                  <NavLink to="/faq/captain">{t('nav.faq_captain_guide')}</NavLink>
                </li>
              </ul>
            </div>
            <hr className="clearfix w-100 d-md-none" />
            <div className="col-md-3 mx-auto">
              <h5 className="font-weight-bold  mt-3 mb-4">{t('nav.register_title')}</h5>
              <NavLink to="/register" className="btn btn-gold btn-sm">
                {t('nav.sign_up_button')}
              </NavLink>
            </div>
          </div>)}
        </div>

        <ul className="list-unstyled list-inline text-center">
          <li className="list-inline-item">
            <a className="btn-floating btn-fb mx-1">
              <i className="fa fa-facebook-f"> </i>
            </a>
          </li>
          <li className="list-inline-item">
            <a className="btn-floating btn-tw mx-1">
              <i className="fa fa-twitter"> </i>
            </a>
          </li>

          <li className="list-inline-item">
            <a className="btn-floating btn-dribbble mx-1">
              <i className="fa fa-dribbble"> </i>
            </a>
          </li>
        </ul>

        <div className="footer-copyright text-center py-3">
          <div>
            <div>
              &copy; {new Date().getFullYear()}, {process.env.REACT_APP_SITE_NAME}
            </div>
          </div>
        </div>
      </footer>
      <footer className="page-footer font-small bg-white pt-4 mt-5" style={{ display: 'none' }}>
        <div className="container text-center text-md-left">
          <div className="row">
            <hr className="clearfix w-100 d-md-none" />
            <div className="col-md-2 mx-auto">
              <h5 className="font-weight-bold  mt-3 mb-4" style={{ color: '#212529' }}>
                {t('nav.company')}
              </h5>
              <ul className="list-unstyled">
                <li>
                  <NavLink to="/about">{t('nav.about')}</NavLink>
                </li>
                <li>
                  <NavLink to="/faq/guest">{t('nav.faq_guest')}</NavLink>
                </li>
                <li>
                  <NavLink to="/faq/captain">{t('nav.faq_captain_guide')}</NavLink>
                </li>
                <li>
                  <NavLink to="/terms">{t('nav.terms')}</NavLink>
                </li>
                <li>
                  <NavLink to="/privacy">{t('nav.privacy')}</NavLink>
                </li>
              </ul>
            </div>
            <hr className="clearfix w-100 d-md-none" />
            <div className="col-md-2 mx-auto">
              <h5 className="font-weight-bold mt-3 mb-4" style={{ color: '#212529' }}>
                {t('nav.contact')}
              </h5>
              <ul className="list-unstyled">
                <li>
                  <a href="mailto: support@fishvoy.com">{t('nav.contactSupport')}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-copyright text-center py-3">
          <p>
            &copy; {new Date().getFullYear()}, {process.env.REACT_APP_SITE_NAME}
          </p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
