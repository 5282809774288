import parse from 'html-react-parser';
import { ButtonSpinner } from '../../components/Spinner/ButtonSpinner';
import { useGetContactPage } from '../../services/User/userService';
import { useTranslation } from 'react-i18next';
import React from 'react';

const Contact = () => {
  const { data, isLoading, refetch } = useGetContactPage();
  const { i18n } = useTranslation();

  React.useEffect(() => {
    refetch();
  }, [i18n.language, refetch]);
  // Make these values reactive to language changes
  const pageTitle = data?.pageTitle || '';
  const pageContent = data?.content || '';


  return (
    <div className="info-page" style={{ paddingBottom: 20 }}>
      <div
        className="container-fluid display_operator_image"
        style={{ backgroundImage: `url(${data?.coverPicture?.url || "/img/image.jpg"})` }}
      >
        <div className="row">
          <div className="display_operator-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center whitetext">
            <h2 className="display_operator_name">{pageTitle}</h2>
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{
          backgroundColor: '#FFF',
          marginTop: '-90px',
          maxWidth: '960px',
        }}
      >
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-5">
            <div className="display_operator_info mb-3">
              {isLoading && <ButtonSpinner />}
              {data && parse(pageContent)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;