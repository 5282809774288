import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { currencyCodes } from '../services/currencyCodes';
const useOptionData = () => {
  const { t } = useTranslation();

  let boatLength = _.map(_.range(3, 250), (value: number) => ({
    label: `${value} ${t('common.feet')}`,
    value: value.toString(),
  }));

  let boatCapicity = _.map(_.range(1, 100), (value: number) => ({
    label: value <= 1 ? `${value} ${t('common.guest')}` : `${value} ${t('common.guests')}`,
    value: value.toString(),
  }));

  let numberOfEngine = _.map(_.range(1, 7), (value: number) => ({
    label: `${value} ${t('common.engines')}`,
    value: value.toString(),
  }));

  const maxCruisingSpeed = _.map(_.range(1, 80), (value: number) => ({
    label: `${value} ${t('common.knot')}`,
    value: value.toString(),
  }));

  const wheelchairAccessibleOptions = [
    { label: `${t('common.yes')}`, value: true },
    { label: `${t('common.no')}`, value: false },
  ];

  const currencyData = _.map(currencyCodes, (value: any) => ({
    label: value.code,
    value: value.code,
  }));

  function getCurrencyByCode(code: string) {
    return (
      _.find(currencyCodes, function (currency) {
        return currency.code === code;
      })?.symbol_native || '$'
    );
  }

  const tripTypesOptions = [
    { label: `${t('trip_type.half_day')}`, value: 'Half_Day' },
    { label: `${t('trip_type.full_day')}`, value: 'Full_Day' },
    { label: `${t('trip_type.multi_day')}`, value: 'Multi_Day' },
  ];

  const radioFieldOptions = [
    { label: `${t('common.yes')}`, value: true },
    { label: `${t('common.no')}`, value: false },
  ];

  const alcoholPolicyOptions = [
    { label: `${t('common.allowed')}`, value: true },
    { label: `${t('common.not_allowed')}`, value: false },
  ];

  const daysOptions = [
    { label: t('days.abbreviation.mon'), value: 'Mon' },
    { label: t('days.abbreviation.tue'), value: 'Tue' },
    { label: t('days.abbreviation.wed'), value: 'Wed' },
    { label: t('days.abbreviation.thu'), value: 'Thu' },
    { label: t('days.abbreviation.fri'), value: 'Fri' },
    { label: t('days.abbreviation.sat'), value: 'Sat' },
    { label: t('days.abbreviation.sun'), value: 'Sun' },
  ];

  let hours = _.map(_.range(1, 24), (value: number) => ({
    label: value <= 1 ? `${value} ${t('common.hour')}` : `${value} ${t('common.hours')}`,
    value: value.toString(),
  }));

  // let departureTime = _.map(_.range(1, 11), (value: number) => ({
  //   label: `${value} ${t('common.am')}`,
  //   value: value.toString(),
  // }));
  let departureTime = (() => {
    const times = [];
    
    // Add AM times (12 AM and 1-11 AM)
    times.push({ label: `12 ${t('common.am')}`, value: '0' });
    for (let i = 1; i < 12; i++) {
      times.push({ label: `${i} ${t('common.am')}`, value: i.toString() });
    }
    
    // Add PM times (12 PM and 1-11 PM)
    times.push({ label: `12 ${t('common.pm')}`, value: '12' });
    for (let i = 1; i < 12; i++) {
      times.push({ label: `${i} ${t('common.pm')}`, value: (i + 12).toString() });
    }
    
    return times;
  })();
  const numberofGuests = _.map(_.range(1, 51), (value: number) => ({
    label: value <= 1 ? `${value} ${t('common.guest')}` : `${value} ${t('common.guests')}`,
    value: value.toString(),
  }));

  let minAges = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  const minAgeOptions = _.map(minAges, (value: number) => ({
    label: value <= 1 ? `${value} ${t('common.year')}` : `${value} ${t('common.years')}`,
    value: value.toString(),
  }));

  let dayCancelations = [1, 3, 7, 14, 30, 60];

  return {
    hours,
    dayCancelations,
    departureTime,
    numberofGuests,
    minAgeOptions,
    currencyData,
    tripTypesOptions,
    radioFieldOptions,
    alcoholPolicyOptions,
    daysOptions,
    boatLength,
    boatCapicity,
    numberOfEngine,
    maxCruisingSpeed,
    wheelchairAccessibleOptions,
    getCurrencyByCode,
  };
};

export default useOptionData;
