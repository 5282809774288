import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextAreaField, TextField } from '../../../components/Form';
import { ButtonSpinner } from '../../../components/Spinner/ButtonSpinner';
import { useUser } from '../../../lib/auth';
import { useSendBookingRequest } from '../../../services/Message/messageService';

interface ModelProps {
  show: boolean;
  id: number;
  recipient: string;
  onClose: any;
  languages: string;
}
const BookingRequestModel = (props: ModelProps) => {
  const { t } = useTranslation();
  const { data: userInfo } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const { onClose, show, id, recipient, languages } = props;
  const { mutateAsync: mutateAsyncPost } = useSendBookingRequest();
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      recipient,
      languages
    },
  });
  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const onSubmit = (data: any) => {
    const request = { tripId: id, message: data.message };

    mutateAsyncPost(request, {
      onSuccess: () => {
        NotificationManager.success(t('notification.bookingrequest.sent'));
        onHide();
      },
    });
  };

  const onErrors = (data: any) => {
    console.log(data);
  };

  const isLoadingUpdate = false;
  const onHide = () => {
    if (onClose) onClose(false);
  };
  return (
    <>
      <Modal aria-labelledby="contained-modal-title-vcenter" show={show} onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title className="text-left" style={{ width: '100%' }}>
            {t('common.bookings')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ lineHeight: 1.3, minHeight: '200px' }}>
          <>
            {!userInfo && (
              <>
                <p>{t('booking_request_page.info')}</p>
                <div className="row mt-1">
                  <div className="form-group col-md-12">
                    <div className="mt-2 mb-3 text-end">
                      <Button
                        variant="warning"
                        type="button"
                        className="next btn btn-gold text-uppercase"
                        onClick={() => navigate(`/signin?next=${location.pathname}/${id}`)}
                      >
                        {t('common.register')}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {userInfo && (
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit, onErrors)} noValidate>
                  <div className="row">
                    <div className="form-group col-md-12">
                      <TextField
                        disabled={true}
                        name={`recipient`}
                        label={t('booking_request_page.fields.recipient.label')}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-12">
                      <TextField
                        disabled={true}
                        name={`languages`}
                        label={t('booking_request_page.fields.languages.label')}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-12">
                      <TextAreaField
                        name={`message`}
                        label={t('booking_request_page.fields.message.label')}
                      />
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="form-group col-md-12">
                      <div className="mt-2 mb-3 text-end">
                        <Button variant="secondary" onClick={() => onHide()}>
                          {t('common.close')}
                        </Button>
                        <Button
                          variant="warning"
                          type="submit"
                          className="next btn btn-gold text-uppercase"
                          disabled={!isDirty || isLoadingUpdate}
                        >
                          {isLoadingUpdate ? (
                            <ButtonSpinner message={t('common.sending')} />
                          ) : (
                            t('common.send_message')
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </FormProvider>
            )}
          </>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default BookingRequestModel;
