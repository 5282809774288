import { useEffect, useState } from 'react';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ButtonSpinner } from '../../../components/Spinner/ButtonSpinner';
import i18n from '../../../i18n';
import { currentApi } from '../../../services/Db/dbInfo';
import { nearBySearchTripQueryParam, useSearchTrips } from '../../../services/Search/searchService';
import { getCountry } from '../../../services/countries';

interface RelatedTripProps {
  city: string;
  countryCode: string;
  noofpassanger: number;
}
const RelatedTrip = (props: RelatedTripProps) => {
  const queryParam = nearBySearchTripQueryParam({
    // city: props.city,
    countryCode: props.countryCode,
    noOfPassenger: props.noofpassanger,
  });
  const navigate = useNavigate();

  const { data: trips, isLoading } = useSearchTrips(queryParam);

  // Define the number of cards per slide based on screen size
  const [cardsPerSlide, setCardsPerSlide] = useState(1);

  // Update the number of cards per slide based on screen size
  const updateCardsPerSlide = () => {
    if (window.innerWidth >= 992) {
      setCardsPerSlide(4);
    } else {
      setCardsPerSlide(1);
    }
  };

  // Add an event listener to update cards per slide on window resize
  useEffect(() => {
    window.addEventListener('resize', updateCardsPerSlide);
    // Initial call to set the initial number of cards per slide
    updateCardsPerSlide();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateCardsPerSlide);
    };
  }, []);

  if (isLoading) {
    <div className="row mx-auto my-auto justify-content-center">
      <ButtonSpinner />
    </div>;
  }

  // Function to generate cards for each slide
  const generateCards = () => {
    const cards = [];
    if (trips) {
      for (let i = 0; i < trips.length; i++) {
        const { id, name, city, country, tripMedia, slug } = trips[i];
        cards.push(
          <Col
            key={i}
            lg={12 / cardsPerSlide}
            style={{ padding: '0px', cursor: 'pointer' }}
            onClick={() => {
              navigate(`/${i18n.language}/trip-info/${slug ? slug : id}`);
            }}
          >
            <div className="card">
              <div className="card-img">
                {tripMedia && (
                  <img
                    style={{ objectFit: 'cover', height: '180px', width: '100%' }}
                    className="img-fluid"
                    src={`${currentApi.fileBasePath}${tripMedia[0]?.url}`}
                    alt={name}
                  />
                )}
              </div>
              <div className="card-img-overlay">
                <div
                  style={{
                    backgroundColor: 'rgba(255,255,255,0.5)',
                  }}
                >
                  <h6>{name}</h6>
                  <small>
                    {city}, {getCountry(country, i18n.language)}
                  </small>
                </div>
              </div>
            </div>
          </Col>
        );
      }
    }
    return cards;
  };

  const slides = [];

  if (trips) {
    const totalSlides = Math.ceil(trips.length / cardsPerSlide);

    for (let i = 0; i < totalSlides; i++) {
      const startIndex = i * cardsPerSlide;
      const endIndex = startIndex + cardsPerSlide;
      slides.push(
        <Carousel.Item key={i}>
          <Container>
            <Row>{generateCards().slice(startIndex, endIndex)}</Row>
          </Container>
        </Carousel.Item>
      );
    }
  }

  return (
    <div className="row mx-auto my-auto justify-content-center">
      <Carousel controls={true} indicators={false} className="carousel slide">
        {slides}
      </Carousel>
    </div>
  );
};

export default RelatedTrip;
