import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Spinner } from '../../components/Spinner/Spinner';
import moment from '../../lib/moment';
import { useGetNewsPostById } from '../../services/News/newsService';
import NewsCarousel from './footercarousel';

function ChronicleView() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: newsPost, isLoading: isNewsLoading } = useGetNewsPostById(Number(id));
  console.log('id', id ,newsPost);
  

  return (
    <div id="ledare_page">
      <div className="container">
        {isNewsLoading && <Spinner />}
        {!isNewsLoading && newsPost && (
          <>
            <div className="row text-center pt-sm-5 pt-md-5 pt-lg-5 pt-xl-4 pt-xl-0">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mx-auto p-sm-1 p-md-3 p-lg-4 p-xl-4">
                <div className="ledare_bild p-xl-3">
                  <img className="img-chronicle"
                    src={newsPost?.image?.url}
                    alt="news"
                  />
                </div>
              </div>
              <div
                className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mx-auto p-sm-1 p-md-3 p-lg-4 p-xl-4"
                style={{ position: 'relative' }}
              >
                <div className="p-xl-3">
                  <div className="ledare_linti">
                    {newsPost.type === 'Chronicle' && (
                      <p className="text-uppercase">{t('news.chronicles')}</p>
                    )}
                  </div>
                  <h1 className="text-capitalize">{newsPost?.title}</h1>
                  <div className="ledare_datum">
                    <p>
                      {moment(newsPost?.updatedAt).format('LLLL')} | {` ${newsPost?.author_name}`}
                    </p>
                  </div>
                  <div className="ledare_ingress text-left p-sm-1 p-md-3 p-lg-4 p-xl-4">
                    <div className="p-xl-3">
                      {newsPost.summary && (
                        <>
                          <hr />
                          <p>{newsPost.summary}</p>
                          <hr />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-sm-12 col-md-8 col-lg-8 col-xl-6 mx-auto pt-sm-5 pt-md-5 pt-lg-5 p-xl-0">
                <div>{parse(newsPost?.content)}</div>

                <p className="pt-4">
                  {newsPost.type === 'Chronicle' && (
                    <span
                      style={{ fontFamily: 'Great Vibes', fontSize: '40px', fontWeight: '200' }}
                      className="text-capitalize"
                    >
                      {newsPost?.author_name}
                    </span>
                  )}
                </p>
              </div>
            </div>
          </>
        )}
        <NewsCarousel />
      </div>
    </div>
  );
}

export default ChronicleView;
